import React, { useEffect, useReducer, createContext } from 'react';
import { useContext } from 'react';
import { getAnonymousId } from '~/hooks/utils/customAnalytics/serverTrack/get-anonymous-id';

export const GtagContext = createContext();
const initialState = {
  gtagSessionId: '',
  gtagSessionNumber: '',
  isFetching: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SESSION':
      return {
        ...state,
        gtagSessionId: action.payload.sessionId,
        gtagSessionNumber: action.payload.number,
        isFetching: false,
      };

    default:
      return state;
  }
};
const GtagProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const id = getAnonymousId();

   if (!window.gtag) return;
    window.gtag(
      'config',
      `${process.env.GATSBY_GTAG_GA4}`,
      process.env.NODE_ENV === 'production' ? '' : { debug_mode: true, user_id: id ?? 'unidentified_user' }
    );
    
    const sessionPromise = new Promise((resolve) => {
      window.gtag('get', `${process.env.GATSBY_GTAG_GA4}`, 'session_id', resolve);
    });
    const sessionNumPromise = new Promise((resolve) => {
      window.gtag('get', `${process.env.GATSBY_GTAG_GA4}`, 'session_number', resolve);
    });

    Promise.all([sessionPromise, sessionNumPromise]).then((sessionData) => {
      dispatch({ type: 'UPDATE_SESSION', payload: { sessionId: sessionData[0], number: sessionData[1] } });
    });
  }, []);

  return <GtagContext.Provider value={state}>{children}</GtagContext.Provider>;
};

export const useGtag = () => {
  const gtag = useContext(GtagContext);

  return gtag;
};
export default GtagProvider;
