import React from 'react';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';

export const NavigationContext = createContext();

const NavigationHeightProvider = ({ children }) => {
  const navHeightRef = useRef();
  const [navHeight, setNavHeight] = useState(70);
  const [inner, setInner] = useState(null);

  const forceRecalculate = useCallback((ref) => {
    // usecallback to make sure this doesnt rerender
    // When this function is called, rerender and adjust height of navbar
    setInner(ref);
  }, []);

  const adjustHeight = useCallback((navRef) => {
    if (!navRef.current) return;
    const newHeight = navRef.current.getBoundingClientRect().height;
    setNavHeight(newHeight);
  }, []);

  useEffect(() => {
    // Get navheight dynamically based on total navheight, used for collection icons and cdp sticky categories in mobile
    if (!navHeightRef.current) return;
    adjustHeight(navHeightRef);
    window.addEventListener('resize', () => adjustHeight(navHeightRef));
    return () => window.removeEventListener('resize', () => adjustHeight(navHeightRef));
  }, [navHeightRef, inner]);

  const returnValues = {
    navHeight,
    forceRecalculate,
    navHeightRef,
  };

  return <NavigationContext.Provider value={returnValues}>{children}</NavigationContext.Provider>;
};

export default NavigationHeightProvider;
