import { colors } from './colors';
import { typography } from './typography';
import { oldTheme } from './old-theme';
import { spacingSize } from './spacing';
import { Theme, ThemeUIContextValue, useThemeUI } from 'theme-ui';
import { borderRadius } from './borderRadius';

const makeTheme = <T extends Theme>(t: T) => t;

const newTheme = makeTheme({
  ...oldTheme,
  colors: {
    ...colors,
  },
  radii: {
    ...borderRadius,
  },
  text: {
    ...oldTheme.text,
    ...typography,
  },
  space: {
    ...oldTheme.space,
    ...spacingSize,
  },
});

export type ExactTheme = typeof newTheme;

export interface ExactContextValue extends Omit<ThemeUIContextValue, 'theme'> {
  theme: ExactTheme;
}

export const useTheme = useThemeUI as unknown as () => ExactContextValue;

export default newTheme;
