export const spacingSize = {
  '3xs': '0.8rem', // 3xs
  '2xs': '1.2rem', // 2xs
  xs: '1.4rem', // xs
  '2sm': '1.6rem', // 2sm
  sm: '1.8rem', // sm
  '3md': '2.4rem', // 3md
  '2md': '3.2rem', // 2md
  md: '3.5rem', // md
  lg: '4rem', // lg
  xl: '4.8rem', // xl
  xxl: '5.6rem', // xxl
};
