import React from 'react';
import { Script } from 'gatsby';

const UniversalSitePixel = () => {
  return (
    <Script>
      {` 
    (function () {
      var p, s, d, w;
      d = document;
      w = window.location;
      p = d.createElement('IMG');
      s =
        w.protocol +
        '//tvspix.com/t.png?&t=' +
        new Date().getTime() +
        '&l=tvscientific-pix-o-dcdbd912-26e5-447e-a151-6b2a4f77c28b&u3=' +
        encodeURIComponent(w.href);
      p.setAttribute('src', s);
      p.setAttribute('height', '0');
      p.setAttribute('width', '0');
      p.setAttribute('alt', '');
      p.style.setProperty('display', 'none');
      p.style.setProperty('position', 'absolute');
      p.style.setProperty('visibility', 'hidden');
      d.body.appendChild(p);
    })();
      `}
    </Script>
  );
};

export default UniversalSitePixel;
