import { Theme, ThemeUICSSProperties, ThemeUIStyleObject } from 'theme-ui';

const baseSharedStyle: ThemeUICSSProperties = {
  fontFamily: 'HCo Gotham, Gotham B, system-ui, sans-serif',
  fontStyle: 'normal',
};

const baseHeadingStyle: ThemeUIStyleObject = {
  ...baseSharedStyle,
  fontWeight: 500,
  lineHeight: '100%',
  textTransform: 'uppercase',
};

const baseButtonStyle: ThemeUIStyleObject = {
  ...baseSharedStyle,
  fontWeight: 550,
  lineHeight: '115%',
};

const baseBodyStyle: ThemeUIStyleObject = {
  ...baseSharedStyle,
  fontWeight: 400,
  lineHeight: '150%',
};

export const typography: Theme['text'] = {
  // Those first two should be the only one across most of the site
  heading: {
    fontSize: ['3rem', '3.6rem'],
    lineHeight: [1.1, 1],
    letterSpacing: 'normal',
    fontWeight: 500,
  },
  copy: {
    fontSize: '1.7rem',
    lineHeight: 1.4,
    letterSpacing: 'normal',
    fontWeight: 400,
  },
  PDPTitle: {
    fontSize: ['2.4rem', '3.2rem'],
    lineHeight: [1, 1.1],
    letterSpacing: ['-0.02em', 0],
    textTransform: 'capitalize',
    fontWeight: 500,
  },
  PDPSubTitle: {
    fontSize: ['2rem', '2.4rem'],
    lineHeight: 1,
    letterSpacing: '-0.02em',
    fontWeight: 500,
  },

  // Leaving those here for now as I don't want to break everything before launch.
  display: {
    fontSize: '6.4rem',
    ...baseHeadingStyle,
  },
  h1: {
    fontSize: ['4.4rem', '5.6rem'],
    ...baseHeadingStyle,
  },
  h2: {
    fontSize: ['4rem', '4.8rem'],
    ...baseHeadingStyle,
  },
  h3: {
    fontSize: ['3.6rem', '4rem'],
    ...baseHeadingStyle,
  },
  h4: {
    fontSize: ['2.8rem', '3.6rem'],
    ...baseHeadingStyle,
  },
  h5: {
    fontSize: ['2.4rem', '2.8rem'],
    ...baseHeadingStyle,
  },
  h6: {
    fontSize: '2rem',
    ...baseHeadingStyle,
  },
  h7: {
    fontSize: '1.8rem',
    ...baseHeadingStyle,
  },
  h8: {
    fontSize: '1.6rem',
    ...baseHeadingStyle,
  },

  button: {
    fontSize: '1.4rem',
    ...baseButtonStyle,
  },
  buttonLight: {
    fontSize: '1.4rem',
    ...baseButtonStyle,
    fontWeight: 400,
  },
  buttonLightSmall: {
    fontSize: '1.2rem',
    ...baseButtonStyle,
    fontWeight: 400,
  },
  paragraphMain: {
    fontSize: '1.6rem',
    ...baseBodyStyle,
  },
  paragraphLarge: {
    fontSize: '1.8rem',
    ...baseBodyStyle,
  },
  paragraphSmall: {
    fontSize: '1.4rem',
    ...baseBodyStyle,
  },
  label: {
    fontSize: '1.6rem',
    lineHeight: '1.84rem',
    ...baseBodyStyle,
  },
  quote: {
    fontSize: '1.8rem',
    lineHeight: 1.5,
    fontStyle: 'italic',
    fontWeight: 400,
  },
  quotePrimary: {
    fontSize: ['2rem', '1.8rem'],
    lineHeight: 1.5,
    fontStyle: 'italic',
    fontWeight: 400,
  },
};
