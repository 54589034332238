import { Fragment } from 'react';
import { ConsentManager } from '@segment/consent-manager';
// As seen on Chord's Gatsby-Autonomy-Starter
const getSegmentWriteKey = () => {
  if (process.env.NODE_ENV === 'production' && process.env.GATSBY_NETLIFY_CONTEXT === 'production') {
    return process.env.GATSBY_SEGMENT_PRODUCTION_WRITE_KEY;
  }
  return process.env.GATSBY_SEGMENT_DEV_WRITE_KEY;
};

const onError = (error) => {
  console.error('ConsentManagerBanner error', error);
};

export default function ConsentManagerWrapper({ children }) {
  const writeKey = getSegmentWriteKey();
  return (
    <Fragment>
      {children}
      <div sx={{ position: 'fixed', bottom: 0, left: 0 }}>
        <ConsentManager writeKey={writeKey} onError={onError} shouldRequireConsent={() => false} />
      </div>
    </Fragment>
  );
}
