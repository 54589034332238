import React, { Dispatch, SetStateAction, createContext, useState } from 'react';
import { StripeEmbeddedCheckout } from '@stripe/stripe-js';

interface EmbeddedCheckoutContextData {
  checkout: StripeEmbeddedCheckout | null;
  setCheckout: Dispatch<SetStateAction<StripeEmbeddedCheckout | null>>;
}

const initialContextValue: EmbeddedCheckoutContextData = {
  checkout: null,
  setCheckout: () => {},
};

export const EmbeddedCheckoutContext = createContext<EmbeddedCheckoutContextData>(initialContextValue);

export const EmbeddedCheckoutProvider: React.FC = ({ children }) => {
  const [checkout, setCheckout] = useState<StripeEmbeddedCheckout | null>(null);

  return (
    <EmbeddedCheckoutContext.Provider value={{ checkout, setCheckout }}>{children}</EmbeddedCheckoutContext.Provider>
  );
};

export const useEmbeddedCheckout = () => React.useContext(EmbeddedCheckoutContext);
