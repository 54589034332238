import React from 'react';
import { Global } from '@emotion/react';

import './static/fonts/fonts.css';
import './src/styles/global.css';
import '~/assets/styles/styles.css';
import GtagProvider from '~/context/GtagContext';
import NavigationHeightProvider from '~/context/NavigationContext/NavigationHeightContext';
import ConsentManagerWrapper from '~/components/ConsentManagerWrapper';
import UniversalSitePixel from '~/scripts/NBCU/UniversalSitePixel';
import { EmbeddedCheckoutProvider } from '~/context/EmbeddedCheckoutContext';
import { Script } from 'gatsby';
// https://github.com/gatsbyjs/gatsby/issues/18866#issuecomment-844449917
let nextRoute = ``;

export const onPreRouteUpdate = ({ location }) => {
  nextRoute = location.pathname;
};

export const shouldUpdateScroll = ({ routerProps }) => {
  // Disable scroll by passing this prop in navigation settings

  const { disableScrollUpdate } = routerProps.location.state ?? {};
  if (!disableScrollUpdate || !routerProps.location.hash) return [0, 0];
};

window.addEventListener('unhandledrejection', (event) => {
  if (/loading chunk \d* failed./i.test(event.reason)) {
    if (nextRoute) {
      window.location.pathname = nextRoute;
    }
  }
});

export const wrapRootElement = ({ element }) => (
  <>
    <Script src="https://cdn-widgetsrepository.yotpo.com/v1/loader/oYv9wq456iGUWIwK3fxjaKzHpClWABISLeT7xVPL" async />

    <ConsentManagerWrapper>
      <GtagProvider>
        <Global
          styles={{
            html: {
              height: '-webkit-fill-available',
              fontSize: '62.5%',
              scrollBehavior: 'smooth',
              textSizeAdjust: '100%',
            },
            body: {
              minHeight: '100vh',
              minHeight: '-webkit-fill-available',
              minHeight: '-moz-available',
              minHeight: '-webkit-fill-available',
              minHeight: 'fill-available',
              minHeight: 'stretch',
              fontSize: '1.8rem', // 18px
              // textRendering: 'optimizeLegibility',
              // WebkitFontSmoothing: 'antialiased',
              // MozOsxFontSmoothing: 'grayscale',
              // fontSmooth: 'antialiased',
            },
          }}
        />
        <UniversalSitePixel />
        <NavigationHeightProvider>
          <EmbeddedCheckoutProvider>{element}</EmbeddedCheckoutProvider>
        </NavigationHeightProvider>
      </GtagProvider>
    </ConsentManagerWrapper>
  </>
);
